import "./../styles.css";
import { motion } from "framer-motion";

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

export default function About() {
  return (
    <div className="screen">
      <div className="screen-wrapper">
        <motion.div
          className="textContainer"
          variants={textVariants}
          initial="initial"
          animate="animate"
        >
          <motion.h3
            style={{ letterSpacing: "0.2rem" }}
            className="h1"
            variants={textVariants}
          >
            Über mich
          </motion.h3>

          <motion.p
            variants={textVariants}
            style={{ color: "#fff", fontSize: "27px" }}
          >
            <li>
              Ich bin ein Fullstack Software Entwickler, spezialisiert in React JS
              und Spring Boot
            </li>
            <br></br>
            <li>
              Ich habe für eine Vielzahl an Kunden erfolgreich Projekte
              geliefert
            </li>
            <br></br>
            <li>
              Ich helfe Ihnen dabei, Ihren Nutzern eine nahtlose und fesselnde
              UX zu bieten
            </li>
            <br></br>
            <li>
              Ich stehe zur Verfügung, um Projekt- und Entwicklungsbedürfnisse
              zu besprechen
            </li>
          </motion.p>
          <div className="about-info-container">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="about-info-card"
            >
              <div className="about-info-card-title">
                <div style={{ padding: "20px" }}>Jahrgang</div>
              </div>
              <div className="about-info-card-content">
                <div style={{ padding: "20px" }}>2003</div>
              </div>
            </motion.div>
            <div className="about-info-card-spacer"/>
            <motion.div
              whileHover={{ scale: 1.05 }}
              style={{ marginLeft: "2%" }}
              className="about-info-card"
            >
              <div className="about-info-card-title">
                <div style={{ padding: "20px" }}>Berufserfahrung</div>
              </div>
              <div className="about-info-card-content">
                <div style={{ padding: "20px" }}>seit 2021</div>
              </div>
            </motion.div>
            <div className="about-info-card-spacer"/>
            <motion.div
              whileHover={{ scale: 1.05 }}
              style={{ marginLeft: "2%" }}
              className="about-info-card"
            >
              <div className="about-info-card-title">
                <div style={{ padding: "20px" }}>Hobbies</div>
              </div>
              <div className="about-info-card-content">
                <div style={{ padding: "20px" }}>
                  Lesen, Segeln, Diving, Tech Affinität
                </div>
              </div>
            </motion.div>
            <div className="about-info-card-spacer"/>
            <motion.div
              whileHover={{ scale: 1.05 }}
              style={{ marginLeft: "2%" }}
              className="about-info-card"
            >
              <div className="about-info-card-title">
                <div style={{ padding: "20px" }}>Sprachen</div>
              </div>
              <div className="about-info-card-content">
                <div style={{ padding: "20px" }}>
                  Deutsch, Englisch, Russisch
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
