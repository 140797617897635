import "./../styles.css";
import { motion } from "framer-motion";
import linkedIn from "./../static/linkedin.png";
import xing from "./../static/xing.png";
import Footer from "../components/Footer";

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

export default function Contact() {
  return (
    <>
      <div className="contact">
        <div className="screen-wrapper">
          <motion.div
            className="textContainer"
            variants={textVariants}
            initial="initial"
            animate="animate"
          >
            <motion.h3
              style={{ letterSpacing: ".4rem" }}
              className="h1"
              variants={textVariants}
            >
              Let's connect
            </motion.h3>
            <motion.h2 style={{ fontSize: "30px" }}>
              Email<br></br>
              <motion.a whileHover={{ color: "#5f2e90" }}>
                info@dmitrij-wasiljev.de
              </motion.a>
            </motion.h2>
            <motion.h2 style={{ fontSize: "30px" }}>
              Telefon<br></br>
              <motion.a whileHover={{ color: "#5f2e90" }}>
                +49 176 6202 7868
              </motion.a>
            </motion.h2>
            <motion.h2 style={{ fontSize: "30px" }}>
              Social<br></br>
              <motion.a href="https://www.xing.com/profile/Dmitrij_Wasiljev2">
                <motion.img
                  alt=""
                  whileHover={{ scale: 1.2 }}
                  className="contact-social-image"
                  src={xing}
                ></motion.img>
              </motion.a>
              <motion.a href="https://www.linkedin.com/in/dmitrij-wasiljev-03102222a/">
                <motion.img
                  alt=""
                  whileHover={{ scale: 1.2 }}
                  className="contact-social-image"
                  src={linkedIn}
                ></motion.img>
              </motion.a>
            </motion.h2>
          </motion.div>
        </div>
      </div>
      <Footer />
    </>
  );
}
