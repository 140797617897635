import React from "react";
import "./../styles.css";
import { motion } from "framer-motion";

export default function Links() {
  return (
    <div className="links">
      <motion.a whileHover={{ scale: 1.1 }} href="#hero">Home</motion.a>
      <motion.a whileHover={{ scale: 1.1 }} href="#about">Über mich</motion.a>
      <motion.a whileHover={{ scale: 1.1 }} href="#skills">Kompetenzen</motion.a>
      <motion.a whileHover={{ scale: 1.1 }} href="#projects">Projekte</motion.a>
      <motion.a whileHover={{ scale: 1.1 }} href="#education">Qualifikation</motion.a>
      <motion.a whileHover={{ scale: 1.1 }} href="#contact">Kontakt</motion.a>
    </div>
  );
}
