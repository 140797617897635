import "./../styles.css";
import { motion } from "framer-motion";
import SkillsSlider from "./../components/SkillsSlider";
import LogoBar from "./../components/LogoBar";

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

export default function Skills() {
  return (
    <>
      <div className="screen">
        <div className="screen-wrapper">
          <motion.div
            className="textContainer"
            variants={textVariants}
            initial="initial"
            animate="animate"
          >
            <motion.h1 style={{ color: "#fff", letterSpacing: "0.2rem" }}>
              MEINE KENNTNISSE
            </motion.h1>
            <SkillsSlider />
            <LogoBar />
          </motion.div>
        </div>
      </div>
    </>
  );
}
