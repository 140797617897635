import React from "react";
import react from "./../static/react.png";
import redux from "./../static/redux.png";
import git from "./../static/git.png";
import js from "./../static/javascript.png";
import jira from "./../static/jira.png";
import k8s from "./../static/kubernetes.png";
import mongo from "./../static/mongo.png";
import node from "./../static/node.png";
import spring from "./../static/spring.png";
import ts from "./../static/typescript.png";

import { motion } from "framer-motion";

export default function LogoBar() {
  return (
    <div className="logobarContainer">
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://react.dev/"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={react} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://spring.io/"
        style={{ marginInline: "2%" }}
      >
        <img
          alt=""
          src={spring}
          style={{ width: "50px", height: "auto" }}
        ></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://kubernetes.io/de/"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={k8s} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://www.typescriptlang.org/"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={ts} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a whileHover={{ scale: 1.3 }} style={{ marginInline: "2%" }}>
        <img alt="" src={js} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://redux.js.org/"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={redux} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://www.mongodb.com/"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={mongo} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://nodejs.org/en"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={node} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <motion.a whileHover={{ scale: 1.3 }} style={{ marginInline: "2%" }}>
        <img alt="" src={git} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
      <br></br>
      <motion.a
        whileHover={{ scale: 1.3 }}
        href="https://www.atlassian.com/de/software/jira"
        style={{ marginInline: "2%" }}
      >
        <img alt="" src={jira} style={{ width: "50px", height: "auto" }}></img>
      </motion.a>
    </div>
  );
}
