import "./../styles.css";
import { motion } from "framer-motion";
import ProjectSlider from "../components/ProjectSlider";

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

export default function Projects() {
  return (
    <div className="screen">
      <div className="screen-wrapper">
        <motion.div
          className="textContainer"
          variants={textVariants}
          initial="initial"
          animate="animate"
        >
          <motion.h1 style={{ color: "#fff", letterSpacing: "0.2rem" }}>
            MEINE PROJEKTE
          </motion.h1>
          <ProjectSlider />
        </motion.div>
      </div>
    </div>
  );
}
