import { Box, Drawer } from "@mui/material";
import linkedIn from "./../static/linkedin.png";
import xing from "./../static/xing.png";
import { motion } from "framer-motion";
import "./../styles.css";

export default function SideBar({ openDrawer, onClose, closeDrawer }) {
  return (
    <Drawer open={openDrawer} onClose={onClose}>
      <Box className="sidebar" role="presentation" onClick={closeDrawer}>
        <motion.a
          className="sidebar-link"
          whileHover={{ scale: 1.1 }}
          href="#hero"
        >
          Home
        </motion.a>
        <motion.a
          className="sidebar-link"
          whileHover={{ scale: 1.1 }}
          href="#about"
        >
          Über mich
        </motion.a>
        <motion.a
          className="sidebar-link"
          whileHover={{ scale: 1.1 }}
          href="#skills"
        >
          Kompetenzen
        </motion.a>
        <motion.a
          className="sidebar-link"
          whileHover={{ scale: 1.1 }}
          href="#projects"
        >
          Projekte
        </motion.a>
        <motion.a
          className="sidebar-link"
          whileHover={{ scale: 1.1 }}
          href="#education"
        >
          Qualifikation
        </motion.a>
        <motion.a
          className="sidebar-link"
          whileHover={{ scale: 1.1 }}
          href="#contact"
        >
          Kontakt
        </motion.a>
        <div className="sidebar-social-container">
          <motion.a
            whileHover={{ scale: 1.3 }}
            href="https://www.xing.com/profile/Dmitrij_Wasiljev2"
          >
            <img alt="" className="sidebar-image" src={xing}></img>
          </motion.a>
          <motion.a
            whileHover={{ scale: 1.3 }}
            href="https://www.linkedin.com/in/dmitrij-wasiljev-03102222a/"
          >
            <img alt="" className="sidebar-image" src={linkedIn}></img>
          </motion.a>
        </div>
      </Box>
    </Drawer>
  );
}
