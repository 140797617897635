import "./../styles.css";
import { motion } from "framer-motion";
import sg from "./../static/sg_logo.webp";
import tum from "./../static/TUM.png";
import react from "./../static/react.png";

export default function EducationCards() {
  return (
    <div className="education-cards-container">
      <motion.div
        whileHover={{
          scale: 1.05,
          backgroundColor: "#5f2e90",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        className="education-card"
      >
        <img
          alt=""
          src={sg}
          style={{
            width: "10%",
            marginRight: "2%",
            borderRadius: "5px",
          }}
        />
        <h3 style={{ fontSize: "25px" }}>
          <b>Gymnasium</b>
        </h3>
      </motion.div>
      <div className="education-card-spacer" />
      <motion.div
        whileHover={{
          scale: 1.05,
          backgroundColor: "#5f2e90",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        className="education-card"
      >
        <img
          alt=""
          src={tum}
          style={{
            width: "10%",
            marginRight: "2%",
            borderRadius: "0px",
          }}
        />
        <h3 style={{ fontSize: "25px" }}>
          <b>Technische Universität München</b>
        </h3>
      </motion.div>
      <div className="education-card-spacer" />
      <motion.a
        whileHover={{
          scale: 1.05,
          backgroundColor: "#5f2e90",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        className="education-card"
        href="https://cert.nobleprog.com/certificate/681775/d46d0"
      >
        <img
          alt=""
          src={react}
          style={{
            width: "10%",
            marginRight: "2%",
            borderRadius: "10px",
          }}
        />
        <h3 style={{ fontSize: "25px" }}>
          <b>React Native für iOS und Android Zertifizierung</b>
        </h3>
      </motion.a>
    </div>
  );
}
