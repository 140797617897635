import React from "react";
import { motion } from "framer-motion";

export default function FooterLinks() {
  return (
    <div className="footerLinks">
      <motion.a
        whileHover={{ scale: 1.1 }}
        href="#hero"
        style={{ textDecoration: "none", color: "white" }}
      >
        Home
      </motion.a>
      <motion.a
        whileHover={{ scale: 1.1 }}
        href="#about"
        style={{ textDecoration: "none", color: "white" }}
      >
        Über mich
      </motion.a>
      <motion.a
        whileHover={{ scale: 1.1 }}
        href="#skills"
        style={{ textDecoration: "none", color: "white" }}
      >
        Kompetenzen
      </motion.a>
      <motion.a
        whileHover={{ scale: 1.1 }}
        href="#projects"
        style={{ textDecoration: "none", color: "white" }}
      >
        Projekte
      </motion.a>
      <motion.a
        whileHover={{ scale: 1.1 }}
        href="#education"
        style={{ textDecoration: "none", color: "white" }}
      >
        Qualifikation
      </motion.a>
      <motion.a
        whileHover={{ scale: 1.1 }}
        href="#contact"
        style={{ textDecoration: "none", color: "white" }}
      >
        Kontakt
      </motion.a>
    </div>
  );
}
