import React from "react";
import { motion } from "framer-motion";

export default function ProjectSlider() {
  return (
    <div className="projectSliderContainer">
      <motion.div whileHover={{ scale: 1.02 }} className="projects">
        <h3 style={{ color: "#38BDF8" }}>1 Jahr</h3>
        <p>
          Entwicklung einer Fullstack-Anwendung für ein weltweit führendes
          Bauunternehmen zur Verwaltung von Korrosionsschutzanlagen
        </p>
        <br></br>
        <p>
          {" "}
          <h3 style={{ color: "#38BDF8" }}>Technologien</h3>
          React JS, microservice architecture, search engine, cloud und CI/CD
        </p>
        <br></br>
        <p>
          <h3 style={{ color: "#38BDF8" }}>Lead role</h3>
          Konzipierung, Architektur, Implementierung, Koordination zwischen PO
          und dev team
        </p>
      </motion.div>
      <div className="projectsMargin" />
      <motion.div whileHover={{ scale: 1.02 }} className="projects">
        <h3 style={{ color: "#38BDF8" }}>6 Monate</h3>
        <p>
          Entwicklung einer mobilen Anwendung zur Kontrolle und Statistikführung
          von Energiekosten und Energieverbrauch
        </p>
        <br></br>
        <p>
          {" "}
          <h3 style={{ color: "#38BDF8" }}>Technologien</h3>
          React Native, CI/CD
        </p>
        <br></br>
        <p>
          <h3 style={{ color: "#38BDF8" }}>Mobile developer role</h3>
          Design, UX, Implementierung, CI/CD
        </p>
      </motion.div>
      <div className="projectsMargin" />
      <motion.div whileHover={{ scale: 1.02 }} className="projects">
        <h3 style={{ color: "#38BDF8" }}>6 Monate</h3>
        <p>Implementierung eines B2C Fluginformationsmanagement Portals</p>
        <br></br>
        <p>
          {" "}
          <h3 style={{ color: "#38BDF8" }}>Technologien</h3>
          React JS, Spring Boot und MongoDB
        </p>
        <br></br>
        <p>
          <h3 style={{ color: "#38BDF8" }}>Lead frontend developer role</h3>
          React JS Implementierung, Konzipierung und Anbindung von Backend API
        </p>
      </motion.div>
      <div className="projectsMargin" />

      <motion.div whileHover={{ scale: 1.02 }} className="projects">
        <h3 style={{ color: "#38BDF8" }}>3 Monate</h3>
        <p>
          Entwicklung einer mobilen App zum Thema Umweltverbesserung und
          regionale Entsorgung zum Visualisieren und Tracken von Abfallverbrauch
        </p>
        <br></br>
        <p>
          {" "}
          <h3 style={{ color: "#38BDF8" }}>Technologien</h3>
          React Native
        </p>
        <br></br>
        <p>
          <h3 style={{ color: "#38BDF8" }}>Role</h3>
          Ideenfindung, Implementierung, UI/UX, CI/CD
        </p>
      </motion.div>
    </div>
  );
}
