import React from "react";
import "./../styles.css";
import Links from "./Links";
import linkedIn from "./../static/linkedin.png";
import xing from "./../static/xing.png";
import { motion } from "framer-motion";

const StickyNavbar = () => {
  //FOR HIDING NAVBAR:
  // const [visible, setVisible] = useState(true);
  // const timeoutRef = useRef(null);

  // const handleMouseMove = (e) => {
  //   if (window.scrollY >= window.innerHeight) {
  //     if (e.clientY < 100) {
  //       if (!timeoutRef.current) {
  //         timeoutRef.current = setTimeout(() => {
  //           setVisible(true);
  //           timeoutRef.current = null;
  //         }, 300);
  //       }
  //     } else {
  //       clearTimeout(timeoutRef.current);
  //       timeoutRef.current = null;
  //       setVisible(false);
  //     }
  //   }
  // };

  // const handleScroll = () => {
  //   if (window.scrollY >= window.innerHeight) {
  //     setVisible(false);
  //   } else {
  //     setVisible(true);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("mousemove", handleMouseMove);
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("mousemove", handleMouseMove);
  //     window.removeEventListener("scroll", handleScroll);
  //     clearTimeout(timeoutRef.current);
  //   };
  // }, []);

  return (
    // <div className={`navbar ${visible ? "visible" : ""}`}> //FOR HIDING NAVBAR
    <div className="navbar">
      <Links />
      <div className="social">
        <motion.a
          whileHover={{ scale: 1.3 }}
          href="https://www.xing.com/profile/Dmitrij_Wasiljev2"
        >
          <img alt="" className="navbar-social-image" src={xing}></img>
        </motion.a>
        <motion.a
          whileHover={{ scale: 1.3 }}
          href="https://www.linkedin.com/in/dmitrij-wasiljev-03102222a/"
        >
          <img alt="" className="navbar-social-image" src={linkedIn}></img>
        </motion.a>
      </div>
    </div>
  );
};

export default StickyNavbar;
