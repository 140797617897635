import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";

export default function SkillsSlider() {
  return (
    <div className="skillsSliderContainer">
      <motion.div
        whileHover={{
          scale: 1.1,
          backgroundColor: "#5f2e90",
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
        }}
        className="skills-container"
      >
        <div>
          <h3 style={{ fontSize: "25px", color: "#38BDF8" }}>
            <b>Frontend</b>
          </h3>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              React JS
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              React Native
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              Next JS
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              TypeScript
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              Design System
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              Redux
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              Testing mit Jest
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              JSON
            </motion.a>
          </div>
        </div>
      </motion.div>

      <motion.div
        whileHover={{ scale: 1.1, backgroundColor: "#5f2e90" }}
        className="backend-skills"
      >
        <div>
          <h3 style={{ fontSize: "25px", color: "#38BDF8" }}>
            <b>Backend & cloud</b>
          </h3>{" "}
          <div style={{ display: "flex", flexDirection: "column" }}>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              Spring Boot
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              OpenAPI
            </motion.a>

            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              Kubernetes
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              GIT
            </motion.a>
            <motion.a
              whileHover={{
                scale: 1.1,
                borderRadius: "20px",
              }}
              style={{ marginBlock: "5px" }}
            >
              CI/CD
            </motion.a>
          </div>
        </div>
      </motion.div>
      <motion.div
        whileHover={{ scale: 1.1, backgroundColor: "#5f2e90" }}
        className="skills-container"
      >
        <h3 style={{ fontSize: "25px", color: "#38BDF8" }}>
          <b>Weitere</b>
        </h3>{" "}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            paddingInline: "5%",
          }}
        >
          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
            style={{ marginBlock: "5px" }}
          >
            Code quality & architecture best practices
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
            style={{ marginBlock: "5px" }}
          >
            Konzepierung & Visualisierung von technischen Anforderungen(Mockups)
          </motion.a>

          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
            style={{ marginBlock: "5px" }}
          >
            Anforderungsanalyse
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
            style={{ marginBlock: "5px" }}
          >
            Selbstständige Arbeit
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
            style={{ marginBlock: "5px" }}
          >
            Projektmanagement{" "}
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
          >
            Scrum & Kanbum{" "}
          </motion.a>
          <motion.a
            whileHover={{
              scale: 1.1,
              borderRadius: "20px",
            }}
            style={{ marginBlock: "5px" }}
          >
            Jira & Redmine{" "}
          </motion.a>
        </div>
      </motion.div>
    </div>
  );
}
