import "./App.css";
import Hero from "./screens/Hero";
import "./styles.css";
import About from "./screens/About";
import Education from "./screens/Education";
import Skills from "./screens/Skills";
import Projects from "./screens/Projects";
import Contact from "./screens/Contact";
import StickyNavbar from "./components/StickyNavbar";
import ScrollToTopButton from "./components/ScrollToTopButton";

function App() {
  return (
    <>
      <ScrollToTopButton />
      <StickyNavbar />
      <section id="hero">
        <Hero />
      </section>
      <section id="about">
        <About />
      </section>
      <section id="skills">
        <Skills />
      </section>
      <section id="projects">
        <Projects />
      </section>
      <section id="education">
        <Education />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </>
  );
}

export default App;
