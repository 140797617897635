import React from "react";
import FooterLinks from "./FooterLinks";

export default function Footer() {
  let currentYear = new Date().getFullYear();

  return (
    <footer className="footerContainer">
      <FooterLinks/>
      <div className="footerCopyright">
        <p style={{ color: "white", fontSize: "15px" }}>
          © Copyright {currentYear}. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}
