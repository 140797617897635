import "./../styles.css";
import { motion } from "framer-motion";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import SideBar from "../components/Sidebar";

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const sliderVariants = {
  initial: {
    x: 0,
  },
  animate: {
    x: "-75%",
    transition: {
      duration: 20,
      repeatType: "mirror",
      repeat: Infinity,
    },
  },
};

export default function Hero() {
  const [open, setOpen] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <>
      <div className="hero-navbar-placeholder">
        <div className="menu-icon" onClick={toggleDrawer(true)}>
          <MenuIcon color="white" fontSize="large" />
        </div>
        <SideBar
          onClose={toggleDrawer(false)}
          openDrawer={open}
          closeDrawer={toggleDrawer(false)}
        />
      </div>{" "}
      <div className="hero">
        <div className="screen-wrapper">
          <motion.div
            className="textContainer"
            variants={textVariants}
            initial="initial"
            animate="animate"
          >
            <motion.h3
              style={{ letterSpacing: ".4rem" }}
              className="h1"
              variants={textVariants}
            >
              DMITRIJ WASILJEV
            </motion.h3>
            <motion.h2 style={{ fontSize: "35px" }}>
              Fullstack Software Engineer, <br></br>
              spezialisiert in der Entwicklung von Webapps mit{" "}
              <motion.a whileHover={{ color: "#5f2e90" }}>
                React JS, React Native, Spring Boot
              </motion.a>{" "}
              & mehr
            </motion.h2>
          </motion.div>
          <motion.div
            className="slidingTextContainer"
            variants={sliderVariants}
            initial="initial"
            animate="animate"
          >
            Konzipierung & Entwicklung
          </motion.div>
        </div>
      </div>
    </>
  );
}
